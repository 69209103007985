import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import App from "./App";
import NotFound from "./pages/404/404";
import Timer from "./pages/timer/Timer";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Timer />} />

        {/* <Route path={routes.partners.path} element={<Partners />} /> */}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
