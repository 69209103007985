const routes = {
  home: {
    path: "/",
    name: "Home",
  },
  partners: {
    path: "/partners",
    name: "Partners",
  },
  about: {
    path: "/about",
    name: "About",
  },
  contact: {
    path: "/contact",
    name: "Contact",
  },
  404: {
    path: "/404",
    name: "Page Not Found",
  },
};

export default routes;
