import style from "./Timer.module.scss";

import Logo from "../../assets/logo/logo.png";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";

import { Ticker } from "../../components/Timer/Ticker/Ticker";
import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

function Timer() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = new Date(2022, 3, 22).getTime() - new Date().getTime();
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className={style.pageContainer}>
        <img src={Logo} alt="Logo" className="w-40 md:w-60" />

        <h1>Coming Soon</h1>
        <h3>Stay tuned for something amazing</h3>

        <div className={style.timerContainer}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              color="inherit"
              variant="determinate"
              value={progress}
              valueBuffer={100}
            />
          </Box>
          <Ticker />
        </div>

        <div className={style.iconContainer}>
          <a href="https://www.linkedin.com/company/ruxas/">
            <LinkedIn className={style.icon} />{" "}
          </a>
          <a href="https://www.facebook.com/Ruxas.co/">
            <Facebook className={style.icon} />
          </a>
          <a href="https://www.instagram.com/ruxas.co/?hl=en">
            <Instagram className={style.icon} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Timer;
