import style from "./Ticker.module.scss";

import { TickerCell } from "../TickerCell/TickerCell";
import { SetTicker } from "../SetTicker";

export const Ticker = () => {
  const { days, hours, minutes, seconds, isTimeUp } = SetTicker();
  const tickerContents = isTimeUp ? (
    <div className={style.timeIsUp}>Time is up!!!</div>
  ) : (
    <>
      <TickerCell value={days} label="Days" />
      <span className={style.separator}>:</span>
      <TickerCell value={hours} label="Hours" />
      <span className={style.separator}>:</span>
      <TickerCell value={minutes} label="Minutes" />
      <span className={style.separator}>:</span>
      <TickerCell value={seconds} label="Seconds" />
    </>
  );

  return <div className={style.tickerShell}>{tickerContents}</div>;
};
