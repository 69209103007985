import { Link } from "react-router-dom";
import { ReactComponent as Image } from "../../assets/images/404/404.svg";
import routes from "../../routes/routeDefinitions";

function NotFound() {
  return (
    <div className="overflow-x-hidden py-10 h-screen bg-blue-850 flex flex-col justify-center items-center">
      <Image className="h-60 sm:h-96" />
      <Link
        to={routes.home.path}
        className={`font-bold py-1 px-10 transition-all bg-white text-blue-800 hover:bg-gray-200 ease-out duration-300 lg:text-xl rounded-lg `}
      >
        Home
      </Link>
    </div>
  );
}

export default NotFound;
